import api from "../api";
import { GET_PUBLIC_POST_DETAILS, GET_PUBLIC_POST,GET_NAVBAR_MENULIST,GET_PUBLIC_LACAL_NEWS,POST_COMMENT,LATEST_POST,MORE_POST, CATEGORY_DETAIL, GLOBAL_SEARCH, FOR_YOU_DATA, YOUR_TOPIC_DATA, PICK_FOR_YOU_DATA, SHOW_TOSTER, STORE_COMMENT, GET_BACK_TO_DETAILS } from "./type";

export const getPublicPost = () => async (dispatch) => {
    try {
        const response = await api.get("/user/posts");
      
        if (response?.status === 200) {
           
        } else {
           
        }
    } catch (error) {
       
    }
}

export const getPostDetails = (payload) => async (dispatch) => {
    // console.log("payload",payload);
    
    try {
        const response = await api.get(`/user/get-post/${payload}`)
        // console.log("getPostDetailsresponse", response);
        if (response?.status === 200) {


            dispatch({ type: GET_PUBLIC_POST_DETAILS, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {

        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}

export const getPublicLocalNews= (payload) => async (dispatch) => {
    try {
        const response = await api.get(`/user/get-local-news?rows=${payload??""}`);
        // console.log("response", response);
        if (response?.status === 200) {
            dispatch({ type: GET_PUBLIC_LACAL_NEWS, payload: response.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "error", message: response?.data?.msg },
            // });
        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}

export const getNavbarMenuList=()=>async(dispatch)=>{
    try {
        const response = await api.get("/user/categories")
        // console.log("getPostDetailsresponse", response);
        if (response?.status === 200) {


            dispatch({ type: GET_NAVBAR_MENULIST, payload: response?.data });
            // dispatch({
            //     type: SHOW_TOSTER,
            //     payload: { type: "success", message: response?.data?.msg },
            // });
        } else {

        }
    } catch (error) {
        // dispatch({
        //     type: SHOW_TOSTER,
        //     payload: { type: "error", message: error?.response?.data?.msg },
        // });
    }
}

export const postComment=(payload)=>async(dispatch)=>{
    // console.log("payload", payload);
    try {
        const response = await api.post(`/user/add-comment`,payload)
        if (response?.status === 200) {


            dispatch({ type: POST_COMMENT, payload: response?.data });
            // dispatch(getPostDetails())
            // dispatch({ type: GET_PUBLIC_POST_DETAILS, payload: response?.data });
        } else {
 
        }
    } catch (error) {
    //    dispatch({
    //         type: SHOW_TOSTER,
    //         payload: { type: "error", message: error?.response?.data?.msg },
    //     });
    }
}
export const storeComment=(payload)=>{
    // console.log("STORE_COMMENT",payload);
    
    return {type:STORE_COMMENT,payload:payload}
}

export const getDetailsId=(payload)=>{
    // console.log("GET_BACK_TO_DETAILS",payload);
    
    return {type:GET_BACK_TO_DETAILS,payload:payload}
}
export const getPublicLatestPost=(payload)=>async(dispatch)=>{

    try {
        const response = await api.get(`/user/latest-posts?rows=${payload??""}`)
        // console.log("responseresponse",response);
        
        if (response?.status === 200) {


            dispatch({ type: LATEST_POST, payload: response?.data });
           
        } else {

        }
    } catch (error) {
       
    } 
}
export const filterpost=(payload)=>async(dispatch)=>{
    // console.log("filterpost",payload);
    

    try {
        const response = await api.get(`/user/latest-posts?category=${payload??""}`)
        // console.log("responseresponse",response);
        
        if (response?.status === 200) {


            dispatch({ type: LATEST_POST, payload: response?.data });
           
        } else {

        }
    } catch (error) {
       
    } 
}

export const morePost=(payload)=>async(dispatch)=>{
    try {
        const response = await api.get(`/user/posts?rows=${payload??""}`)
        // console.log("responseresponse",response);
        
        if (response?.status === 200) {


            dispatch({ type: MORE_POST, payload: response?.data });
           
        } else {

        }
    } catch (error) {
       
    } 
}
export const getCatagoryDetail=(payload)=>async(dispatch)=>{
    // console.log("getCatagoryDetails action",payload);
    
    try {
        const response = await api.get(`user/get-category/${payload}`)
        // console.log("responseresponse",response);
        
        if (response?.status === 200) {
            dispatch({ type: CATEGORY_DETAIL, payload: response?.data });
           
        } else {

        }
    } catch (error) {
       
    } 
}

export const globalSearch=(payload)=>async(dispatch)=>{
    try {
        const response = await api.get(`/user/global-search?search=${payload}`)
        // console.log("responseresponse",response);
        
        if (response?.status === 200) {
            dispatch({ type:GLOBAL_SEARCH, payload: response?.data });
           
        } else {

        }
    } catch (error) {
       
    } 
}
export const getforyoudata=(payload)=>async(dispatch)=>{
    try {
        const response = await api.get(`/user/for-you1?page=1&rows=${payload??""}`)
        // console.log("getforyoudata",response);
        
        if (response?.status === 200) {
            dispatch({ type:FOR_YOU_DATA, payload: response?.data });
           
        } else {

        }
    } catch (error) {
       
    } 
}

export const getyourTopic=(payload)=>async(dispatch)=>{
    try {
        const response = await api.get(`/user/your-topics?page=1&rows=${payload??""}`)
        // console.log("getforyoudata",response);
        
        // console.log("responsegetforyoudata",response?.data);
        if (response?.status === 200) {
            
            dispatch({ type:YOUR_TOPIC_DATA, payload: response?.data });
           
        } else {

        }
    } catch (error) {
       
    } 
}


export const pickforyouData=(payload)=>async(dispatch)=>{
    try {
        const response = await api.get(`/user/picks-for-you?page=1&rows=${payload??""}`)
        // console.log("getforyoudata",response);
        
        // console.log("responsegetforyoudata",response?.data);
        if (response?.status === 200) {
            
            dispatch({ type:PICK_FOR_YOU_DATA, payload: response?.data });
           
        } else {

        }
    } catch (error) {
       
    } 
}