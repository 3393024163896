import { Link, useLocation, useParams } from "react-router-dom";
import WithLayout from "../../components/Layout/Layout";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
// import image1 from "../../assets/image1modipm.png";
import oppsmsg from "../../assets/response-icon/oppsmsg.svg";
import { useEffect, useState } from "react";
import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import { FaSearch } from "react-icons/fa";
// import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  filterpost,
  getCatagoryDetail,
  getPublicLatestPost,
} from "../../redux/actions/publicAction";
import ResponseModel from "../../components/CustomModel/ResponseModel";

const Uspolls = () => {
  let { slug } = useParams();
  const dispatch = useDispatch();
  // console.log("slugUspolls", slug);

  const location = useLocation();
  const [getLoggedIn, setGetLoggedIn] = useState();
  const [filterData, setFilterData] = useState();
  const { navbarlistid, latestPost } = useSelector(
    (state) => state.publicReducer
  );
  // console.log("navbarlistid", navbarlistid);
  // console.log("latestPostuspolls", latestPost);

  const isVerified = localStorage.getItem("accessToken");

  useEffect(() => {
    // const isVerified = localStorage.getItem("accessToken");
    if (isVerified) setGetLoggedIn(isVerified);
  }, [isVerified]);
  // console.log("navbarlistid",navbarlistid);

  const list = Array.isArray(navbarlistid);
  useEffect(() => {
    // console.log("match", navbarlistid?.slug, slug, list);

    if (navbarlistid?.slug === slug) {
      // console.log("true", navbarlistid?.slug, slug, navbarlistid?.id);
      dispatch(filterpost(navbarlistid?.id));
      // console.log("navbarlistid?.id uspoll", navbarlistid?.id);
    } else {
      // console.log("false", navbarlistid?.slug, slug);
    }
  }, [slug, navbarlistid?.slug]);

  // useEffect(()=>{
  //   setFilterData(Array.isArray(latestPost))

  // },[latestPost]);

  // console.log("latestPostuspolls",latestPost);

  function formatSlug(slug) {
    return slug
      .replace(/-/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  // const breadCumb = [
  //   { id: 1, name: formatSlug(slug), link: `/${slug}` },
  //   {
  //     id: 2,
  //     name: " General Stories ",
  //     link: "/general-stories",
  //   },
  // ];
  const pathSegments = location.pathname.split("/").filter(Boolean);
  // pathSegments.pop();
  const breadCumb = [
    ...pathSegments.map((segment, index) => {
      // Construct link for each segment
      const link = `/${pathSegments.slice(0, index + 1).join("/")}`;
      // console.log("link", link);

      return { id: index + 2, name: formatBreadcrumbName(segment), link };
    }),
  ];
  function formatBreadcrumbName(name) {
    return name
      .replace(/-/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");

  return (
    <>
      <div className="bg_home bg-[#F7F8FA] md:bg-[#ffffff] flex flex-col ">
        <div className="">
          <div className="md:hidden my-4 px-4">
            {" "}
            <PreSectionHeader title={"US Polls"} />
          </div>
          <hr className="border-b-1 md:border-none mt-3 md:mt-0 font-semibold" />

          <div className={` flex-row px-40 gap-4 text-[12px] font-semibold  bg-[#E2E7EE] hidden md:flex `}>
            <Link
              to={`/${slug}/general-stories`}
              className={` py-2 ${
                location.pathname === `/${slug}/general-stories`
                  ? "font-bold current-page "
                  : "text-[#7F8FA4]"
              }`}
            >
              General Stories
            </Link>

            {getLoggedIn !== null && getLoggedIn !== undefined ? (
              <Link
              to={`/${slug}/polling`}
                className={`py-2 nav-link-hover ${
                  location.pathname === `/${slug}/polling`
                    ? "font-bold"
                    : "text-[#7F8FA4]"
                }`}
              >
                Polls
              </Link>
            ) : (
              <Link
                to="/login"
                className={`py-2 nav-link-hover ${
                  location.pathname === "/login"
                    ? "font-bold"
                    : "text-[#7F8FA4]"
                }`}
              >
                Polls
              </Link>
            )}
          </div>

          <div className="bg_home md:bg-[#F7F8FA]  px-0 md:px-40 flex flex-col md:pb-14">
            <div className="flex flex-row items-center py-5">
              {breadCumb.map((item, index) => (
                <span
                  key={item.id}
                  className={`text-[10px] font-medium  ${
                    index === breadCumb.length - 1 ? "" : "opacity-60"
                  }`}
                >
                  {index > 0 && <span className="mx-1">&gt;</span>}

                  <Link to={item?.link} className="breadcrumb-link">
                    {item.name}
                  </Link>
                </span>
              ))}
            </div>
            <div className="md:bg-[#FFFFFF] w-full rounded-xl shadow-sm p-3 md:p-5  md:mt-0">
              <div className="bg-white rounded-[10px] flex items-center gap-2 px-4 py-2 md:hidden">
                <FaSearch />
                <div className="h-[35px] w-[1.5px] bg-gray-300" />
                <input
                  type="search"
                  placeholder="Search Keyword"
                  className="border-none outline-none text-xs bg-transparent rounded-md py-1"
                />
              </div>

              <div className="flex flex-row items-center justify-between my-3 md:hidden md:py-0">
                <div className="font-normal text-[18px] mb-2 ">
                  General Stories
                </div>

                <button className="h-10 p-2 rounded-full flex gap-2 items-center justify-center cursor-pointer border w-1/3 md:w-32 bg-white">
                  {/* <MdOutlineSettingsInputComponent /> */}
                  {/* icons add */}
                  <img
                    src=""
                    alt="check-polls-icon"
                    className="h-[15px] w-[15px]"
                  />
                  {getLoggedIn !== null && getLoggedIn !== undefined ? (
                    <Link to={`/${slug}/polling`}>Check polls</Link>
                  ) : (
                    <Link to="/login">Check polls</Link>
                  )}
                </button>
              </div>

              <div className="max-h-auto  ">
                {Array.isArray(latestPost) && latestPost.length > 0 ? (
                  latestPost.map((story) => (
                    // console.log("story",story),
                    
                    // console.log("link",`/general-stories/${createSlug(story?.category?.name)}/${createSlug(story?.title)}/${story?.uuid}`),
                    
                    <Link
                    // to={`/${slug}/general-stories/${story?.slug}`}
                    to={isVerified ? `/${slug}/general-stories/${story?.slug}` : '/login'}
                    key={story.id}
                      className="flex flex-col md:flex-row items-start md:gap-5 my-5 md:my-0 md:pb-5 md:relative bg-[#fff] rounded-2xl"
                    >
                      <img
                        src={story?.file?.[0]?.url}
                        alt={story?.fileId}
                        className="w-full md:w-60 md:h-auto h-56 object-cover rounded-3xl md:rounded-lg"
                      />

                      <div className="flex flex-col gap-1 p-4 md:p-0">
                        <CategoryPolls
                          key={story?.category?.id}
                          title={story?.category?.name}
                          postid={story?.category?.id}
                        />

                        <span className="font-sans text-[#1A1A1A] leading-tight font-medium tracking-normal">
                          {story?.title}
                        </span>
                        <span className="text-[#7F8FA4] text-manage font-normal text-xs leading-[18px] font-open-sans tracking-normal text-custom-gray text-left" 
                         dangerouslySetInnerHTML={{ __html: story?.description }}>
                          {/* {story?.description} */}
                        </span>
                        <span className="date_shown absolute bottom-5">
                          {moment(story?.createdAt).format("MMMM D, YYYY")}
                        </span>
                      </div>
                    </Link>
                  ))
                ) : (
                  <ResponseModel
                    iconRes={oppsmsg}
                    msgRes="Oops!"
                    textRes="Nothing to show..."
                    isPage={true}
                  />
                )}
              </div>
            </div>
            {/* <p></p> */}
          </div>
        </div>
      </div>
    </>
  );
};
Uspolls.Layout = WithLayout;
export default Uspolls;
