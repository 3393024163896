import CategoryPolls from "../../CategoryPolls/CategoryPolls";
import shareicon from "../../../assets/icons/Path 23100.svg";
import { Link } from "react-router-dom";
import LocalNews from "../LocalNewsHomeComp";
import SecHead from "../../SectionHeader/SecHead";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getDetailsId,
  getPostDetails,
  postComment,
  storeComment,
} from "../../../redux/actions/publicAction";
import moment from "moment";
import commentedUser from "../../../assets/icons/Path 23147.svg";
import commentpostsuccess from "../../../assets/response-icon/commentpostsuccess.svg";
import somethingwentwrong from "../../../assets/response-icon/somethingwentwrong.svg";
import ResponseModel from "../../CustomModel/ResponseModel";
import { useLocation, useNavigate } from "react-router-dom";

function TopStoriesDetailsMobileCom({ postDetailsid }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [inputData, setInputData] = useState({ comment: "" });
  const [commentResponseModel, setCommentResponseModel] = useState(false);
  const [responsemsg, setResponsemsg] = useState({});

  const { publicPostDetails, postcommentres, storedComment, getbacktodetails } =
    useSelector((state) => state.publicReducer);
  const { latestPost } = useSelector((state) => state.publicReducer);

  const isVerified = localStorage.getItem("accessToken");

  // console.log("postcommentres", postcommentres);

  useEffect(() => {
    if (getbacktodetails === location?.pathname) {
      if (storedComment) {
        setInputData({
          ...inputData,
          id: storeComment?.id,
          comment: storedComment?.comment,
        });
      }
    } else {
      setInputData({ comment: "" });
    }
  }, [storedComment, getbacktodetails, location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const handleCommentSubmit = (id) => {
    if (isVerified) {
      if (id) {
        dispatch(
          postComment({
            id: id,
            comment: inputData?.comment,
          })
        );
        setInputData({ comment: "" });
        setCommentResponseModel(true);
        dispatch(getPostDetails(postDetailsid)); 
      }
    } else {
      dispatch(getDetailsId(location?.pathname));
      dispatch(
        storeComment({
          id: id,
          comment: inputData?.comment,
        })
      );
      localStorage.setItem("setBack", true);
      navigator("/login");
    }
  };

  useEffect(() => {
    if (postDetailsid) {
      dispatch(getPostDetails(postDetailsid));
    }
  }, [postDetailsid, dispatch]);

  useEffect(() => {
    if (postcommentres) {
      setResponsemsg({ resIcon: commentpostsuccess, resMsg: postcommentres });
    } else {
      setResponsemsg({
        resIcon: somethingwentwrong,
        resMsg: "Something went wrong!",
      });
    }

    if (commentResponseModel) {
      const timeoutId = setTimeout(() => {
        setCommentResponseModel(false);
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [postcommentres, commentResponseModel]);

  return (
    <>
      <div
        className="relative  overflow-hidden h-[18rem] bg-center text-white"
        style={{
          backgroundImage: `url(${publicPostDetails?.file?.[0]?.url})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <span
          className="absolute flex flex-row p-4 h-32"
          style={{ right: "0px" }}
        >
          <div className=" p-2" style={{ right: "0px" }}>
            {" "}
            <img src={shareicon} alt="share" className="h-4" />
          </div>
        </span>

        <div className="absolute inset-0 bg-black bg-opacity-5 flex flex-col justify-end mb-4">
          <div className="flex flex-col p-4">
            <span className=" flex flex-row ">
              {" "}
              <CategoryPolls title={publicPostDetails?.category?.name} />
            </span>

            <div
              className="title_shown mt-2 font-semibold text-2xl leading-tight font-open-sans tracking-normal  w-3/4 "
              style={{ color: "#fff" }}
            >
              {publicPostDetails?.title}
            </div>
            <span className="date_shown mt-2" style={{ color: "#fff" }}>
              {moment(publicPostDetails?.createdAt).format("MMMM D, YYYY")}
            </span>
          </div>
        </div>
      </div>
      <div
        className=" bg-[#F7F8FA]  rounded-t-2xl"
        style={{ marginTop: "-15px", zIndex: 2 }}
      >
        <p className=" mx-5 my-4 font-normal text-[14px] leading-[24px] text-[#7F8FA4] tracking-normal">
          {publicPostDetails?.description}
        </p>
        {/* leave commect section  */}
        <div className="bg-[#FFFFFF] rounded-xl shadow-sm p-4  mx-4">
          {/* <span className="text-[#1A1A1A] font-normal text-sm md:text-[20px]"> */}
          <span className="flex font-sans text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A]">
            Leave a Reply
          </span>
          <div className="my-2">
            <div className="my-2 ">
              <textarea
                className="max-h-auto bg-[#F7F8FA] p-4 rounded-lg text-[10px] h-[10rem]  w-full resize-none"
                placeholder="Write a comment..."
                name="comment"
                value={inputData?.comment ?? ""}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="my-3">
            <div className="flex flex-col items-center justify-center">
              <button
                className={`rounded-lg md:rounded-full  py-2 text-[14px] font-semibold px-20 bg-[#FF3D00] text-white`}
                onClick={() => handleCommentSubmit(publicPostDetails?.id)}
              >
                <span className="opacity-80">Post Comments</span>
              </button>
            </div>
          </div>

          {/* show all comments */}
          <SecHead
            title={`All Comments (${publicPostDetails?.comments?.length || 0})`}
          />
          <div className=" mt-2 border border-gray-100 px-4 rounded-md">
            {Array.isArray(publicPostDetails?.comments) &&
              publicPostDetails?.comments.map((item) => (
                <div
                  //  key={item.id}
                  className="flex flex-row items-start md:items-center gap-4 md:gap-4 py-2  border-b border-gray-300 last:border-none  "
                >
                  <img
                    src={commentedUser}
                    alt="alt"
                    className="   mt-2 w-9 h-auto object-cover rounded-lg bg-[#7F8FA4] p-3 "
                  />
                  <div className="flex flex-col mt-2 md:mt-0 md:pt-1">
                    <span className="font-[12px] text-xs  ">
                      {item?.creator?.firstName ?? "User"}
                      <span className="text-[#7F8FA4] text-xs">
                        {" "}
                        {moment(item?.createdAt).fromNow()}
                      </span>
                    </span>

                    <span className="date_shown mt-1">{item?.text ?? ""}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mx-4 my-4">
          <LocalNews />
        </div>
      </div>
      {/* responsceModel */}
      {commentResponseModel && (
        <ResponseModel
          iconRes={responsemsg?.resIcon}
          msgRes={responsemsg?.resMsg}
        />
      )}
    </>
  );
}
export default TopStoriesDetailsMobileCom;
