import React, { useEffect } from "react";
import SecHead from "../SectionHeader/SecHead";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import { useDispatch, useSelector } from "react-redux";
import { morePost } from "../../redux/actions/publicAction";
import moment from "moment";
import { Link } from "react-router-dom";

const MorePostHomeComp = () => {
  const dispatch = useDispatch();
  const { morepostdata } = useSelector((state) => state.publicReducer);
  const isVerified = localStorage.getItem("accessToken");
  useEffect(() => {
    dispatch(morePost(6));
  }, []);

  // console.log("morepostdata",morepostdata);

  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }

  return (
    <div className="bg-white w-full p-3 md:p-5 px-4 py-4 rounded-lg">
      <SecHead title={"More Posts"} redirectLink="/more-posts" />
      <div className=" ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {Array.isArray(morepostdata) &&
            morepostdata.map((item) => (
              <Link
                // to={`/more-posts-details/${item?.uuid}`}
                // to={`/more-posts/${item?.slug}`}
                to={isVerified ? `/more-posts/${item?.slug}` : '/login'}
              >
                <div
                  key={item.id}
                  className="flex flex-col items-start gap-4 md:gap-2 py-4 "
                >
                  <img
                    src={item?.file?.[0]?.url}
                    alt={item?.fileId}
                    className="w-full h-40 object-cover rounded-md"
                  />
                  <div className="flex flex-col mt-2 md:mt-0 md:pt-1 md:gap-2 md:opacity-90">
                    <CategoryPolls
                      title={item?.category?.name}
                      id={item?.category?.id}
                    />
                    <span className="font-medium text-[#1A1A1A] leading-[18px] text-[14px] tracking-normal opacity-95 ">
                      {item?.title}
                    </span>
                    <span className=" date_shown mt-4">
                      {moment(item[0]?.createdAt).format("MMMM D, YYYY")}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MorePostHomeComp;
