import LoginLayout from "../../components/LoginLayout/LoginLayout";
import loginleftimg from "../../assets/images/Group25196.png";
import googleicon from "../../assets/icons/Group25208.svg";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  loginwithgoogle,
  resendOTP,
  verifyOtp,
} from "../../redux/actions/authAction";
import { email_Regex } from "../../components/globalRegex";
import editicon from "../../assets/icons/Path 23111.svg";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpdivopen, setOtpdivopen] = useState(false);
  const [otpInput, setOtpInput] = useState(new Array(6).fill(""));
  const [isCheckValid, setIsCheckValid] = useState(false);

  const [email, setEmail] = useState("");

  // resend otp hooks
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const { getbacktodetails } = useSelector((state) => state.publicReducer);
  const { otpMessage, error, otpSentStatus, loginDataToken, resendOtp,googleloginDataToken } =
    useSelector((state) => state.auth);

  // console.log("getbacktodetails", getbacktodetails);

  // console.log(
  //   "otpMessage, error,otpSentStatus",
  //   otpMessage,
  //   error,
  //   otpSentStatus
  // );
  // console.log("loginDataToken", loginDataToken);

  // filed validation
  const checkValidation = (type) => {
    if (type === "email") {
      if (!email) {
        return { isValid: false, message: " Email is Required." };
      }
      if (email && !email_Regex.test(email)) {
        return { isValid: false, message: "Invalid Email" };
      }
      return { isValid: true, message: "" };
    }
  };

  // login submit
  useEffect(() => {
    if (otpSentStatus) {
      // console.log("otpSentStatus", otpSentStatus);
      // dispatch({ type: SHOW_TOSTER, message: "Otp sent"});
      setTimeout(() => {
        setOtpdivopen(true);
      }, 500);
    }
  }, [otpSentStatus]);

  // login submit
  const handleSubmit = async () => {
    setIsCheckValid(true);
    if (checkValidation("email").isValid) dispatch(loginUser(email));
    setOtpdivopen(true);
  };

  // Handle OTP input changes
  const handleChange = (element, index) => {
    // console.log("element, index", element, index);

    const value = element.value;

    if (/^\d$/.test(value)) {
      const newOtp = [...otpInput];
      newOtp[index] = value;
      setOtpInput(newOtp);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    } else if (value === "") {
      const newOtp = [...otpInput];
      newOtp[index] = "";
      setOtpInput(newOtp);
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (/^\d{6}$/.test(paste)) {
      setOtpInput(paste.split(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otpInput[index] && index > 0) {
      e.target.previousSibling.focus();
    }
  };

  const handleKeyDownSubmit = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
      setOtpdivopen(true);
    }
  };

  // Handle OTP verification

  const handleVerify = () => {
    const enteredOtp = otpInput.join("");

    if (enteredOtp.length === 6) {
      dispatch(verifyOtp({ email: email, otp: enteredOtp }));
      localStorage.setItem("email", email);
    }
  };

  useEffect(()=>{
    if(googleloginDataToken?.token){
      localStorage.setItem("accessToken",googleloginDataToken?.token)
      navigate("/");
    }else{
      navigate("/login");
    }
  },[googleloginDataToken])

  useEffect(() => {
    if (loginDataToken) {
      // console.log("Login successful:", loginDataToken);
      localStorage.setItem("accessToken", loginDataToken?.token);
      localStorage.setItem("isOnBoard", loginDataToken?.isOnBoard);
      if (loginDataToken?.isOnBoard === true) {
        // console.log("Should go back:", localStorage.getItem("setBack"));
        const shouldGoBack = localStorage.getItem("setBack");
        if (shouldGoBack === "true") {
          // window.history.back();
          // window.history.go(-2);
          navigate(getbacktodetails);
          // console.log("getbacktodetailsgetbacktodetails", getbacktodetails);

          localStorage.removeItem("setBack");
        } else {
          
          navigate("/");
        }
      } else if (loginDataToken?.isOnBoard === false) {
        navigate("/followyourinterests");
      }
    } else {
      navigate("/login");
    }
  }, [loginDataToken, navigate]);

  const handleClick = () => {
    setOtpdivopen(false);
  };

  const handleResendOtp = () => {
    dispatch(resendOTP({ email: email }));

    setIsButtonDisabled(true);
    setTimer(5);
  };

  useEffect(() => {
    let intervalId;

    if (isButtonDisabled && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(intervalId);
            setIsButtonDisabled(false); // Re-enable the button
            return 0;
          }
          return prev - 1;
        });
      }, 1000); // Update every second
    }

    return () => clearInterval(intervalId); // Cleanup interval on component unmount or timer change
  }, [isButtonDisabled, timer]);

  //  login with google

  // const clientId="1093196973989-1sll1mtd3frpcjn5p4v1sml8n31tbp1c.apps.googleusercontent.com"

  // const onSuccess =async (response) => {
  //   console.log('Login Success: current user:', response?.credential);
  //   // Handle the login success, e.g., save user info, redirect, etc.
  //   let rawData = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${response?.credential}`);
  //     let jsonData = await rawData.json();
  //     console.log("jsonData",jsonData);

  // };

  // const onSuccess = async (response) => {
  //   const idToken = response.credential; // This should be the ID token
  //   console.log("Login Success: current user ID Token:", idToken);

  //   // Verify the ID token with Google
  //   let rawData = await fetch(
  //     `https://oauth2.googleapis.com/tokeninfo?id_token=${idToken}`
  //   );
  //   let jsonData = await rawData.json();

  //   if (jsonData.error) {
  //     console.error("Error fetching user info:", jsonData.error_description);
  //   } else {
  //     console.log("User Info:", jsonData);
  //     // Handle successful user info retrieval (e.g., save user data)
  //   }
  // };

  // const onFailure = (response) => {
  //   console.error("Login failed: res:", response);
  //   alert("Login failed. Please try again.");
  // };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="flex-1 relative">
        <img
          src={loginleftimg}
          alt="loginleftimg"
          className="w-full h-full object-cover mobile-shadow-bottom md:web-shadow"
        />
      </div>
      <div
        className="flex-1 flex items-center justify-center md:justify-start md:p-4 md:px-8 my-10 mb-6"
        id="showemailsection"
      >
        {otpdivopen === false ? (
          <div className="w-full  md:max-w-80 md:px-2 px-10 ">
            {/* <div className="text-[26px] font-medium md:font-medium text-gray-800 mb-6  md:mb-2 "> */}
            <div className="font-normal text-[26px]  text-custom-size leading-custom-line tracking-custom-spacing text-custom-gray text-left mb-6 md:mb-0">
              News, Analysis and much more
              <span className="md:hidden "> Join today!</span>
            </div>
            {/* <div className="text-[26px] font-medium text-gray-800 mb-6 hidden md:block"> */}
            <div className="font-normal text-[26px] my-4  md:my-6 text-custom-size leading-custom-line tracking-custom-spacing text-custom-gray text-left  hidden md:block">
              Join today.
            </div>
            <form>
              <div className="pb-4 md:pb-6">
                <div className="input-container">
                  <input
                    type="email"
                    id="email"
                    placeholder=" "
                    // autocomplete="off"
                    value={email}
                    className="bg-transparent focus:bg-transparent"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDownSubmit}
                  />
                  <label htmlFor="email">Enter Email ID</label>
                </div>

                {checkValidation("email") && isCheckValid && (
                  <span className="error-message">
                    {checkValidation("email").message}
                  </span>
                )}
              </div>
              <div className="flex items-center justify-center w-full md:max-w-80">
                <button
                  className={`flex  rounded-[12px] w-full text-center justify-center text-white font-semibold py-4 md:py-2 px-4  focus:outline-none focus:shadow-outline ${
                    email
                      ? "bg-[#FF3D00] hover:bg-[#FF3D00]"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                  type="button"
                  onClick={handleSubmit}
                  disabled={!email}
                >
                  Get OTP
                </button>
              </div>
            </form>
            <div className="relative flex items-center py-5">
              <div className="flex-grow border-t border-gray-400"></div>
              <span className="mx-4 text-gray-500 font-bold text-xs">or</span>
              <div className="flex-grow border-t border-gray-400"></div>
            </div>
            {/* <div className="flex items-center rounded-lg md:rounded-full bg-[#f7f8fa] md:max-w-80 p-1">
              <button
                className="flex items-center justify-center w-full md:w-96 h-10 text-black font-bold py-2 px-4"
                type="button"
              >
                <img src={googleicon} alt="googleicon" className="py-1 " />
                <div className="leading-none font-bold text-gray-500">
                  Continue with Google
                </div>
              </button>
            </div> */}
            <div className="flex items-center rounded-lg md:rounded-full bg-[#f7f8fa] md:max-w-80 p-1 justify-center">
            <GoogleLogin
  onSuccess={credentialResponse => {
    // console.log("credentialResponse",credentialResponse);
    const decoded = jwtDecode(credentialResponse?.credential);
    // console.log("decoded",decoded);
    dispatch(loginwithgoogle(credentialResponse?.credential));
    
  }}
  onError={() => {
    // console.log('Login Failed');
  }}
/>
            {/* <GoogleLogin
      onSuccess={onSuccess}
      onFailure={onFailure}
      render={({ onClick }) => (
        <button
          className="flex items-center justify-center w-full md:w-96 h-10 text-black font-bold py-2 px-4"
          onClick={onClick}
          type="button"
        >
          <img src={googleicon} alt="Google Icon" className="py-1" />
          <div className="leading-none font-bold text-gray-500">
            Continue with Google
          </div>
        </button>
      )}
    /> */}
            </div>
          </div>
        ) : (
          <>
            <div className="w-full max-w-80 px-2">
              <div className="text-3xl font-normal text-gray-600 mb-6">
                News, Analysis, and Much More
              </div>
              <div className="text-2xl font-semibold text-gray-600 mb-3">
                OTP verification
              </div>
              <div className="font-medium text-[14px]">
                <span className="text-gray-600">
                  {" "}
                  We will send you a one time password on this
                </span>{" "}
                <br />
                {/* <div className="flex flex-row text-center ">{email}<img src={editicon} alt="edit-icon" className="h-2 w-2  ms-2 justify-center text-center"  onClick={handleClick} /></div>  */}
                <div className="flex items-center">
                  <span className="text-gray-800 text-lg">{email}</span>
                  <img
                    src={editicon}
                    alt="edit-icon"
                    className="h-3 w-3 ml-2 cursor-pointer"
                    onClick={handleClick}
                  />
                </div>
              </div>
              <div className="py-5">
                <div className="flex space-x-2" onPaste={handlePaste}>
                  {otpInput.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      className="w-[2.75rem] h-12 text-center text-lg border border-[#7F8FA4] rounded-lg focus:outline-none focus:border-[#7F8FA4]"
                      value={digit}
                      onChange={(e) => handleChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                    />
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-center max-w-80 ">
                <button
                  className={`flex h-10 ${
                    otpInput.every((digit) => digit !== "")
                      ? "bg-[#FF3D00] hover:bg-[#FF3D00]"
                      : "bg-gray-400 cursor-not-allowed"
                  } rounded-[12px] w-[150%] text-center justify-center text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline`}
                  type="button"
                  onClick={handleVerify}
                  disabled={!otpInput.every((digit) => digit !== "")}
                >
                  Verify
                </button>
              </div>
              <div className="font-medium text-[14px] my-8 md:my-4 text-center md:text-start text-gray-600">
                Didn't get the OTP?{" "}
                <button
                  className={`text-[#FF3D00] font-semibold ${
                    isButtonDisabled ? "cursor-not-allowed" : ""
                  }`}
                  onClick={handleResendOtp}
                  disabled={isButtonDisabled}
                >
                  Resend
                </button>
                <br />
                {/* <span className="text-xs text-[#FF3D00]">{resendOtp}</span> */}
                {isButtonDisabled && (
                  <span className="text-xs text-[#FF3D00]">
                    {/* Retry in {formatTime(timer)}  */}
                    {resendOtp}
                  </span>
                )}
                {/* {!isButtonDisabled && resendOtp && (
        <span className="text-xs text-[#FF3D00]">{resendOtp}</span>
      )} */}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
Login.Layout = LoginLayout;
export default Login;
