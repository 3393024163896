import React, { useEffect } from "react";
import SecHead from "../SectionHeader/SecHead";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pickforyouData } from "../../redux/actions/publicAction";
import moment from "moment";

const PickForYouHomeComp = () => {
  const dispatch = useDispatch();
  const { pickforyou } = useSelector((state) => state.publicReducer);
  const isVerified = localStorage.getItem("accessToken");
  useEffect(() => {
    dispatch(pickforyouData(4));
  }, []);
  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    // console.log("str",str.replace(/[^a-zA-Z0-9-_]/g, ''));

    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }
  return (
    <div className="bg-white w-full  p-3 md:p-5 py-4 px-4 rounded-lg">
      <SecHead title={"Picks For You"} redirectLink="/picks-for-you" />
      <div className=" ">
        {Array.isArray(pickforyou) &&
          pickforyou.map((item, index) => (
            <Link
              // to={`/pick-for-you-details/${item?.uuid}`}
              // to={`/picks-for-you/${item?.slug}`}
              to={isVerified ? `/picks-for-you/${item?.slug}` : '/login'}
            >
              <div
                key={item.id}
                className={`flex flex-row items-start justify-between gap-4 md:gap-4 py-2 border-b border-gray-300  ${
                  index === pickforyou.length - 1 ? "border-none" : "border-b"
                }`}
              >
                <div className="flex flex-col mt-2 md:mt-0 md:pt-1 md:gap-2 md:opacity-90">
                  <CategoryPolls
                    title={item?.category?.name}
                    id={item?.category?.id}
                  />
                  <span className="font-medium md:w-[25rem] text-[#1A1A1A] leading-[18px] text-[14px] tracking-normal opacity-95 ">
                    {item?.title}
                  </span>

                  <span className="date_shown mt-4">
                    {moment(item[0]?.createdAt).format("MMMM D, YYYY")}
                  </span>
                </div>
                <div className="flex flex-col  mt-2 md:mt-0">
                <img
                  src={item?.file?.[0]?.url}
                  alt={item?.fileId}
                  className="  w-20 mt-2 md:w-30 h-auto object-cover rounded-md"
                />
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default PickForYouHomeComp;
