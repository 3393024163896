import React, { useEffect, useState } from "react";
import url from "../../assets/image1modipm.png";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import SecHead from "../SectionHeader/SecHead";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { topStories } from "../../redux/actions/privateApiAction";
import ResponseModel from "../CustomModel/ResponseModel";
import oppsmsg from "../../assets/response-icon/oppsmsg.svg";

const TopStoriesHomeComp = () => {
  // console.log("checkToken", checkToken);

  const dispatch = useDispatch();
  const [homePageData, setHomePageData] = useState([]);

  const { topStoriesData } = useSelector((state) => state.privateReducer);

  const isVerified = localStorage.getItem("accessToken");

  useEffect(() => {
    // if (!isVerified) {
    //   dispatch(getPublicPost());
    // } else {
    // TODO: top stories api
    dispatch(topStories(6));
    // }
  }, [dispatch]);

  useEffect(() => {
    // if (!isVerified) {
    //   setHomePageData(publicPost);
    // } else {
    setHomePageData(topStoriesData);
    // }
  }, [topStoriesData]);

  return (
    <>
      <div className="block md:hidden">
        {Array.isArray(homePageData) && homePageData.length > 0 ? (
          <Link
            // to={`/top-story-details/${homePageData?.[0]?.uuid}`}
            // to={`/top-stories/${homePageData[0]?.slug}`}
            to={isVerified ? `/top-stories/${homePageData[0]?.slug}` : "/login"}
          >
            <div
              key={homePageData?.[0]?.id}
              className="relative rounded-2xl overflow-hidden h-64 bg-cover bg-center text-white"
              style={{
                backgroundImage: `url(${homePageData?.[0]?.file?.[0]?.url})`,
              }}
            >
              <span className="absolute text-sm font-semibold text-white p-4">
                <CategoryPolls
                  key={homePageData[0]?.category?.id}
                  title={homePageData[0]?.category?.name}
                  postid={homePageData[0]?.category?.id}
                />
              </span>
              <div className="absolute inset-0 bg-black bg-opacity-10 flex flex-col justify-end">
                <div className="flex flex-col p-4">
                  <h3 className="title_shown mt-2" style={{ color: "#fff" }}>
                    {homePageData[0]?.title}
                  </h3>
                  <span className="date_shown mt-4" style={{ color: "#fff" }}>
                    {moment(homePageData[0]?.createdAt).format("MMMM D, YYYY")}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ) : (
          <ResponseModel
            iconRes={oppsmsg}
            msgRes="Oops!"
            textRes="Nothing to show..."
            isPage={true}
          />
        )}
      </div>

      <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 md:hidden">
        <SecHead title={"Top Stories"} redirectLink="/top-stories" />
        <div className="flex space-x-4 overflow-x-scroll scrollbar-hide pt-3 h-42">
          {Array.isArray(homePageData) && homePageData.length > 0 ? (
            homePageData.map((post) => (
              // console.log("post", post),
              <div
                key={post?.uuid}
                className="flex-shrink-0 w-64 h-40 relative rounded-lg overflow-hidden bg-cover bg-center"
              >
                <Link
                  // to={`${isVerified?/top-stories/${post?.slug}}:/login`}
                  to={isVerified ? `/top-stories/${post?.slug}` : "/login"}
                  key={post?.id}
                  className="absolute inset-0  bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${post?.file?.[0]?.url})`,
                    backgroundRepeat: "none",
                  }}
                >
                  <span className="absolute text-sm font-semibold text-white p-4">
                    <CategoryPolls
                      key={post?.category?.id}
                      title={post?.category?.name}
                      postid={post?.category?.id}
                    />
                  </span>
                  <div className="absolute inset-0 bg-black bg-opacity-10 flex flex-col justify-end">
                    <div className="flex flex-col p-4">
                      <h3
                        className="subtitle_shown mt-2"
                        style={{ color: "#fff" }}
                      >
                        {post?.title}
                      </h3>
                      <span
                        className="date_shown mt-4"
                        style={{ color: "#fff" }}
                      >
                        {moment(post?.createdAt).format("MMMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <ResponseModel
              iconRes={oppsmsg}
              msgRes="Oops!"
              textRes="Nothing to show..."
              isPage={true}
            />
          )}
        </div>
      </div>

      {/* Web view: Display all stories */}
      <div className="bg-white w-full  p-3 md:p-5 hidden md:block rounded-lg">
        <SecHead title={"Top Stories"} redirectLink="/top-stories" />

        {/* <div className=" "> */}
        {Array.isArray(homePageData) && homePageData.length > 0 ? (
          homePageData.map((post) => (
            // console.log(post, "post"),
            <Link
              // to={`/top-stories/${post?.slug}`}
              to={isVerified ? `/top-stories/${post?.slug}` : "/login"}
              key={post?.id}
              className="flex flex-col md:flex-row items-start gap-5 py-4  relative"
            >
              <img
                src={post?.file?.[0]?.url}
                alt={post?.title}
                className="w-full md:w-[14rem] h-auto object-cover rounded-md"
              />
              <div className="flex flex-col gap-2 opacity-90">
                <CategoryPolls
                  key={post?.category?.id}
                  title={post?.category?.name}
                  postid={post?.category?.id}
                />

                <span className="text-[16px] text-[#1A1A1A] leading-[18px] font-medium tracking-normal opacity-95">
                  {post?.title}
                </span>
                {/* <span className="text-[#7F8FA4]  text-manage font-normal text-xs leading-[18px] font-open-sans tracking-normal text-custom-gray text-left">
               {post?.description}
                </span> */}
                {/* {console.log("post?.description",post?.description)
                } */}
                <span
  className="text-[#7F8FA4] text-manage font-normal text-xs leading-[18px] font-open-sans tracking-normal text-custom-gray text-left"
  dangerouslySetInnerHTML={{ __html: post?.description }}
/>

                <span className=" date_shown absolute bottom-3">
                  {moment(post?.createdAt).format("MMMM D, YYYY")}
                </span>
              </div>
            </Link>
          ))
        ) : (
          <div className="h-[50rem] ">
            <ResponseModel
              iconRes={oppsmsg}
              msgRes="Oops!"
              textRes="Nothing to show..."
              isPage={true}
            />
          </div>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

export default TopStoriesHomeComp;
